import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './youtube.module.scss';

const BASE_URL = 'https://www.youtube-nocookie.com/embed';

const YouTube = ({ id, title }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const iframeRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoaded(true);
          observer.disconnect();
        }
      },
      { threshold: 0.25 } // trigger when 25% of the element is visible
    );

    if (iframeRef.current) {
      observer.observe(iframeRef.current);
    }

    return () => {
      if (iframeRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  const url = `${BASE_URL}/${id}?color=white`;

  return (
    <div role="presentation" className={styles.wrapper} ref={iframeRef}>
      {isLoaded && (
        <iframe
          src={url}
          title={title}
          className={styles.iframe}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      )}
    </div>
  );
};

YouTube.defaultProps = {
  id: null,
};

YouTube.propTypes = {
  id: PropTypes.string.isRequired,
};

export default YouTube;
